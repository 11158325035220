<template>
    <div>
        <zw-select-group v-model="massMethodVal"
                         :options="getMassMethods()"
                         name="mass_methods"
                         :label-prefix="labelPrefix"
        ></zw-select-group>
        <b-row>
            <b-col cols="12" v-if="massMethodVal == 'replace'">
                <label>{{ $t(labelPrefix + 'old_value') }}</label>
                <multiselect
                    v-model="oldVal"
                    :options="getGroups()"
                    :multiple="false"
                    label="name"
                >
                </multiselect>
            </b-col>
            <b-col cols="12" v-if="massMethodVal != 'delete_all'">
                <label>{{ $t(labelPrefix + 'new_value') }}</label>
                <multiselect
                    v-model="inputVal"
                    :options="getGroups()"
                    :multiple="!(massMethodVal == 'replace')"
                    label="name">
                </multiselect>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
        'oldValue': {},
        'massMethods':{}
    },
    methods: {
        ...mapGetters('CommonData', ['getMassMethods', 'getGroups']),
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        oldVal: {
            get() {
                return this.oldValue;
            },
            set(val) {
                this.$emit('input-old', val);
            }
        },
        massMethodVal: {
            get() {
                return this.massMethods;
            },
            set(val) {
                this.$emit('input-mass-method', val);
            }
        },
    }
}
</script>